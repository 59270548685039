/**
 * @author Tempest
 * @email tar118@pitt.edu
 * @create date 2022-08-31 14:40:03
 * @modify date 2023-02-21 15:55:54
 * @desc Markdown Editor CSS Style
 */
.markdown-editor-container {
    height: auto;
    min-height: 561px;
    overflow: hidden;
}

.fancy-textarea {
    width: 100%;
    outline: 0;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    border: none;
    background-color: #FFF;
    border-left: 4px solid #feb272;
    resize: none;
    font: inherit;
}

.markdown-preview-container {
    height: auto;
    overflow: hidden;
}
.preview-panel {
    border-top: 4px solid #feb272;
    padding: 20px;
}