.flip-switch {
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  height: 35px;
  width: 62px;
  background: #ffffff;
  padding: 0;
  margin: 0 auto;
  font-size: medium;
  /* perspective: 50px; */
  -webkit-perspective: 50px;
  -moz-perspective: 50px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
}

.small-switch {
  height: 25px;
  width: 45px;
  font-size: small;
}

.large-switch {
  height: 45px;
  width: 75px;
  font-size: large;
}

.flip-switch input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 100%;
  width: 1px;
  height: 1px;
}

.flip-switch .flip-bg, .flip-switch .before, .flip-switch .after {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  outline: none;
  margin: 0;
  -webkit-appearance: none;
  background: none;
  border: none;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  border-radius: inherit;
  -webkit-border-radius: inherit;
  -moz-border-radius: inherit;
  animation: uncheck 0.6s ease-out;
  -webkit-animation: uncheck 0.6s ease-out;
  -moz-animation: uncheck 0.6s ease-out;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.flip-switch .after {
  z-index: 2;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  background: #77E371;
}

.flip-switch .before {
  z-index: 2;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  background: #979898;
}

.flip-switch input:checked + label {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  animation: check 0.6s ease-out;
  -webkit-animation: check 0.6s ease-out;
  -moz-animation: check 0.6s ease-out;
}


@keyframes check {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(195deg);
  }
  75% {
    transform: rotateY(165deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes check {
  0% {
    -webkit-transform: rotateY(0deg);
  }
  50% {
    -webkit-transform: rotateY(195deg);
  }
  75% {
    -webkit-transform: rotateY(165deg);
  }
  100% {
    -webkit-transform: rotateY(180deg);
  }
}
@-moz-keyframes check {
  0% {
    -moz-transform: rotateY(0deg);
  }
  50% {
    -moz-transform: rotateY(195deg);
  }
  75% {
    -moz-transform: rotateY(165deg);
  }
  100% {
    -moz-transform: rotateY(180deg);
  }
}
@keyframes uncheck {
  0% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(-15deg);
  }
  75% {
    transform: rotateY(15deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes uncheck {
  0% {
    -webkit-transform: rotateY(180deg);
  }
  50% {
    -webkit-transform: rotateY(-15deg);
  }
  75% {
    -webkit-transform: rotateY(15deg);
  }
  100% {
    -webkit-transform: rotateY(0deg);
  }
}
@-moz-keyframes uncheck {
  0% {
    -moz-transform: rotateY(180deg);
  }
  50% {
    -moz-transform: rotateY(-15deg);
  }
  75% {
    -moz-transform: rotateY(15deg);
  }
  100% {
    -moz-transform: rotateY(0deg);
  }
}