/**
 * @author Tempest
 * @email tar118@pitt.edu
 * @create date 2022-08-31 12:59:50
 * @modify date 2022-09-02 16:52:45
 * @desc Header CSS
 */
.header-link-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin-right: 20px;
    cursor: pointer;
}

.header-link-wrapper:hover {
    color: #feb272;
}

.header-link-wrapper > a:hover {
    color: #feb272;
}

.header-link-wrapper > a[data-active='active'] {
    color: #feb272!important;
}

.header-controller {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
}

.header-theme-toggler {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    line-height: 1;
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
}