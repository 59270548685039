/**
 * @author Tempest
 * @email tar118@pitt.edu
 * @create date 2022-08-31 14:39:25
 * @modify date 2023-02-21 15:56:02
 * @desc Global CSS for this application
 */
* {
  font-family: Hack,consolas,Menlo-Regular,Menlo,Monaco,'ubuntu mono',monospace,monospace;
}

::-webkit-scrollbar {
  /* hide the scroller background */
  background-color: #fff;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(254, 178, 114, 0.7);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(254, 178, 114);
}

html, body, body > #root {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.page {
  display: block;
  background-color: #ffffff;
  color: #212529;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 20px;
}
